import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/main.css";
import "./assets/playmore.css";
import * as Sentry from "@sentry/vue";

const app = createApp(App);

// Initialize Sentry
Sentry.init({
  app,
  dsn: "https://be00774052ebb1c048ecb0b7367bcbed@o4508270333788160.ingest.de.sentry.io/4508273616355408",
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  tracePropagationTargets: [
    "localhost",
    /^https:\/\/zephyr\.playmore\.pl\/api/,
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

app.use(store);
app.use(router);

// Initialize dark mode
const isDarkMode = localStorage.getItem("darkMode") === "true";
if (isDarkMode) {
  document.documentElement.classList.add("dark");
}

store.dispatch("employees/checkAuth").then(() => {
  app.mount("#app");
});
