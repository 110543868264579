import { createRouter, createWebHistory } from "vue-router";
import store from "../store";
import { generateRoutes } from "./auto-import";
import { clientsApi } from "@/api/modules/clients";

const routeConfigs = [
  {
    path: "/login",
    name: "EmployeeLogin",
    component: "login/LoginView",
    props: (route) => ({ magicToken: route.query.magicToken }),
  },
  {
    path: "/logout",
    name: "EmployeeLogout",
    component: "login/LogoutView",
  },
  {
    path: "/",
    name: "Home",
    component: "calendar/Calendar",
    meta: { requiresAuth: true, requiresVenue: true },
  },
  {
    path: "/calendar",
    name: "Calendar",
    component: "calendar/Calendar",
    meta: { requiresAuth: true, requiresVenue: true },
  },
  {
    path: "/employees",
    name: "Employees",
    component: "employee-list/employees",
    meta: { requiresAuth: true, requiresVenue: true },
  },
  {
    path: "/reports",
    name: "Reports",
    component: "reports/home",
    meta: { requiresAuth: true, requiresVenue: true },
    children: [
      {
        path: "new-users",
        name: "NewUsersReport",
        component: "reports/new-users/NewUsersReport",
      },
      {
        path: "payments",
        name: "PaymentsReport",
        component: "reports/payments/PaymentsReport",
      },
      {
        path: "reservations",
        name: "ReservationsReport",
        component: "reports/reservations/ReservationsReport",
      },
      {
        path: "reception",
        name: "ReceptionReport",
        component: "reports/reception/ReceptionReport",
      },
      {
        path: "notifications",
        name: "NotificationsReport",
        component: "reports/notifications/NotificationsReport",
      },
      {
        path: "carnets",
        name: "CarnetsReport",
        component: "reports/carnets/CarnetsReport",
      },
      {
        path: "classes",
        name: "ClassesReport",
        component: "reports/classes/ClassesReport",
      },
      {
        path: "courts",
        name: "CourtsReport",
        component: "reports/courts/CourtsReport",
      },
    ],
  },
  {
    path: "/settings",
    name: "VenueSettings",
    component: "venueSettings/home",
    meta: { requiresAuth: true, requiresVenue: true },
    children: [
      {
        path: "basic",
        name: "BasicSettings",
        component: "venueSettings/venue/BasicSettings",
      },
      {
        path: "tags",
        name: "TagsSettings",
        component: "venueSettings/tags/TagsSettings",
      },
      {
        path: "sports-facilities",
        name: "SportsFacilitiesSettings",
        component: "venueSettings/sportObjects/SportsFacilitiesSettings",
      },
      {
        path: "seasons",
        name: "SeasonsSettings",
        component: "venueSettings/seasons/SeasonsSettings",
      },
      {
        path: "pricing",
        name: "PricingSettings",
        component: "venueSettings/pricing/PricingSettings",
      },
      {
        path: "pricing/:id",
        name: "SinglePriceList",
        component: "venueSettings/pricing/SinglePriceList",
        props: true,
      },
      {
        path: "pricing/:id/edit/:priceListId",
        name: "SinglePriceEdit",
        component: "venueSettings/pricing/SinglePriceEdit",
        props: true,
      },
      {
        path: "carnets",
        name: "CarnetSettings",
        component: "venueSettings/carnets/CarnetSettings",
      },
      {
        path: "/carnets/:id/edit",
        name: "EditCarnet",
        component: "venueSettings/carnets/EditCarnet",
      },
    ],
  },
  {
    path: "/clients",
    name: "Clients",
    component: "clients/clients",
    meta: { requiresAuth: true, requiresVenue: true },
    children: [
      {
        path: "info/:id",
        name: "ClientInfo",
        component: "clients/info",
        props: true,
      },
      {
        path: "reservations/:id",
        name: "ClientReservations",
        component: "clients/reservations",
        props: true,
      },
      {
        path: "recurring-reservations/:id",
        name: "ClientRecurringReservations",
        component: "clients/recurringReservations",
        props: true,
      },
      {
        path: "carnets/:id",
        name: "ClientCarnets",
        component: "clients/carnets",
        props: true,
      },
      {
        path: "trainer-profile/:id",
        name: "TrainerProfile",
        component: "clients/trainerProfile",
        props: true,
      },
    ],
  },
];

const routes = generateRoutes(routeConfigs);

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const isAuthenticated = store.getters["employees/isAuthenticated"];
  const hasSelectedVenue = store.getters["employees/hasSelectedVenue"];

  console.log(
    `Route check: isAuthenticated=${isAuthenticated}, hasSelectedVenue=${hasSelectedVenue}`
  );

  if (to.name === "EmployeeLogin" && to.query.magicToken) {
    next();
  } else if (to.meta.requiresAuth && !isAuthenticated) {
    next("/login");
  } else if (to.meta.requiresVenue && isAuthenticated && !hasSelectedVenue) {
    next("/login");
  } else if (to.path === "/login" && isAuthenticated && hasSelectedVenue) {
    next("/");
  } else if (to.name === "ClientInfo" && to.params.id === "first") {
    try {
      const response = await clientsApi.getAllClients();
      const clients = response.data;
      if (clients.length > 0) {
        next({ name: "ClientInfo", params: { id: clients[0].id.toString() } });
      } else {
        next("/clients");
      }
    } catch (error) {
      console.error("Error fetching clients:", error);
      next("/clients");
    }
  } else {
    next();
  }
});

export default router;
